import React from 'react';
import { Upload as AntdUpload } from 'antd';
import { getAccessToken } from 'utils/authority';
import queryString from 'query-string';

const Upload = ({ children, type, ...props }) => {
  return (
    <AntdUpload
      action={
        'https://dev.naviedu.namvietmedia.vn/api/v1/cloud/upload' +
        (type ? '?' + queryString.stringify({ type }) : '')
      }
      headers={{ authorization: `Bearer ${getAccessToken()}` }}
      {...props}
    >
      {children}
    </AntdUpload>
  );
};

export default Upload;
